import React, { useEffect, useRef, lazy, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Pagination, EffectCreative } from "swiper/modules";
import Tilt from "react-parallax-tilt";
import { PageSlides, SlideParallaxType } from "@re_point/react-page-slides";

import FullScroll from "react-full-scroll";

// css
import "./css/tailwind.css";
import "./css/style.css";
import "swiper/css";
import "swiper/css/pagination";
import { Animation } from "./helper/functions";
import { AiOutlineInstagram } from "react-icons/ai";
import { LiaTelegramPlane } from "react-icons/lia";
import { BiLogoFacebook } from "react-icons/bi";
import pc from "./images/pc3.jpg";
import teamPic from "./images/computer-1.jpg";
import contact from "./images/pc4.jpg";
import projects from "./images/team-bg.jpg";
import skillPic from "./images/pc.jpg";

// components
const Skills = lazy(() => import("./components/Skills"));
const Home = lazy(() => import("./components/Home"));
const ContactUs = lazy(() => import("./components/ContactUs"));
const Projects = lazy(() => import("./components/Projects"));
const OurTeam = lazy(() => import("./components/OurTeam"));

function App() {
  const [index, setIndex] = useState(0);
  const slideNumber = [1, 2, 3, 4, 5];

  useEffect(() => {
    document.addEventListener("mousemove", (e) => {
      document.querySelectorAll(".pulse-circle").forEach((move) => {
        let moveValue = move.getAttribute("data-value");

        let x = (e.clientX * moveValue) / 240;
        let y = (e.clientY * moveValue) / 240;

        move.style.transform = `translateX(${x}px) translateY(${y}px)`;
      });

      const cursor = document.querySelector(".cursor");
      const cursor2 = document.querySelector(".cursor2");

      cursor.style.cssText =
        cursor2.style.cssText = `left: ${e.clientX}px; top:${e.clientY}px`;
    });

    Animation();
  }, []);

  const slides = [
    {
      content: <Home />,
      style: {
        backgroundImage: `url(${pc})`,
      },
    },
    {
      content: <OurTeam />,
      style: {
        backgroundImage: `url(${teamPic})`,
      },
    },
    {
      content: <Skills />,
      style: {
        backgroundImage: `url(${skillPic})`,
      },
    },
    {
      content: <Projects />,
      style: {
        backgroundImage: `url(${projects})`,
      },
    },
    {
      content: <ContactUs />,
      style: {
        backgroundImage: `url(${contact})`,
      },
    },
  ];

  const pageHandler = (i) => {
    setIndex(i);
  };

  return (
    <div className="w-full h-screen">
      <div className="cursor hidden md:block"></div>
      <div className="cursor2 hidden md:block"></div>
      <Swiper
        direction={"vertical"}
        pagination={{
          clickable: true,
        }}
        grabCursor={true}
        effect={'creative'}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, '-100%', 0],
          },
          next: {
            translate: [0, "85%", -1],
          },
        }}
        mousewheel={true}
        modules={[Pagination,Mousewheel,EffectCreative]}
        className="page-slider"
      >
        <SwiperSlide>
          <Home />
        </SwiperSlide>
        <SwiperSlide>
          <OurTeam />
        </SwiperSlide>
        <SwiperSlide>
          <Skills />
        </SwiperSlide>
        <SwiperSlide>
          <Projects />
        </SwiperSlide>
        <SwiperSlide>
          <ContactUs />
        </SwiperSlide>
      </Swiper>

      {/* <div className="pages z-50 !opacity-0 md:!opacity-100 transition-all duration-300">
        {slideNumber.map((num, i) => (
          <div
            onClick={() => pageHandler(i)}
            className={`page-bullet cursor-pointer ${index === i && "active"}`}
          ></div>
        ))}
      </div> */}

      <div className="flex absolute left-5 top-2/4 -translate-y-2/4 bg-[#212c2c52] opacity-0 md:opacity-100 scale-[0.8] md:scale-100 p-2 rounded-full items-center flex-col gap-5 z-10 transition-all duration-300">
        <a href="https://www.instagram.com/webfardaco/">
          <AiOutlineInstagram className="text-[28px] text-white" />
        </a>
        <LiaTelegramPlane className="text-[25px] text-white" />
        <BiLogoFacebook className="text-[28px] text-white" />
      </div>
    </div>
  );
}

export default App;
