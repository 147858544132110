// get window size
const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

const Animation = () => {
  const ob = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("show");
      } else {
        entry.target.classList.remove("show");
      }
    });
  });

  const hidden = document.querySelectorAll(".not-show");
  hidden.forEach((el) => ob.observe(el));
};

export { getWindowSize, Animation };
